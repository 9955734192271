import React from "react"

const Headings = ({
  title,
  description,
  bg = "uk-background-default",
  styleClass,
}) => {
  return (
    <div className={`uk-section ${bg} uk-padding-remove`}>
      <div
        className="uk-container"
        style={{ marginBottom: "20px", marginTop: "20px" }}
      >
        <header>
          <h3 className="uk-margin-remove-bottom uk-text-bold uk-visible@m">
            <span className="green-grad">{title}</span>
          </h3>
          <h4 className="uk-margin-remove-bottom uk-text-bold uk-hidden@m">
            <span className="green-grad">{title}</span>
          </h4>
          <div
            className={`headingUnderline ${styleClass ? styleClass : ""}`}
          ></div>
          {description ? (
            <div className="uk-margin-small-top">
              <p className="uk-margin-remove">{description}</p>
            </div>
          ) : null}
        </header>
      </div>
    </div>
  )
}

export default Headings
